.container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.mouse-position{
    position:absolute;
    top: 50px;
    right: 20px;
    z-index: 100;
    width: 200px;
    height: 20px;
    border: solid red 1px;
    background-color: aliceblue;
    color: red;
}