.contaioner_main_page {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;;
}

.card_main_page {
    max-width: 345px;
    border-radius: 30px!important;
    border: 1px gray solid;
    margin: 10px;
    padding: 10px;
}

.card_main_page img {
    border-radius: 26px 26px 0 0 !important;
}

@media only screen and (max-width: 800px) {
    .contaioner_main_page {
        grid-template-columns: repeat(2, 1fr);
    }  
}

@media only screen and (max-width: 500px) {
    .contaioner_main_page {
        grid-template-columns: repeat(1, 1fr);
    }  
}